import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const REFRESH_INTERVAL = 180000;

const Table = () => {
	const { t } = useTranslation();
	const [trades, setTrades] = useState({
		KGS: { price: '-', volume: '-' },
		RUB: { price: '-', volume: '-' },
		EUR: { price: '-', volume: '-' },
		USD: { price: '-', volume: '-' },
		CNY: { price: '-', volume: '-' },
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Функция для добавления процента к цене
	const addPercentage = (price, percentage) => {
		// Заменяем запятую на точку, если она есть
		const formattedPrice =
			typeof price === 'string' ? price.replace(',', '.') : price;
		const parsedPrice = parseFloat(formattedPrice);
		if (isNaN(parsedPrice)) return '-';
		return (parsedPrice * (1 + percentage / 100)).toFixed(4);
	};

	const fetchTrades = async () => {
		try {
			setLoading(true);
			const response = await axios.get('https://api2.evde.kg/api/rates');

			setTrades({
				KGS: {
					price: addPercentage(response.data.kgsRate, 1.5),
					volume: '500000',
				},
				RUB: {
					price: addPercentage(response.data.usdRubRate, 2.5), // 2.5% для RUB
					volume: '1000000',
				},
				EUR: {
					price: addPercentage(response.data.eurRate, 1.5),
					volume: '500000',
				},
				USD: { price: '1.015', volume: '500000' },
				CNY: {
					price: addPercentage(response.data.cnyRate, 1.5),
					volume: '500000',
				},
			});
			setLoading(false);
		} catch (error) {
			console.error('Ошибка при получении данных:', error);
			setError('Произошла ошибка при загрузке данных');
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTrades();
		const intervalId = setInterval(fetchTrades, REFRESH_INTERVAL);
		return () => clearInterval(intervalId);
	}, []);

	if (loading) return <div>Загрузка...</div>;
	if (error) return <div>{error}</div>;

	const tradeInfo = Object.entries(trades).map(([currency, data]) => ({
		currency,
		price: data.price,
		volume: data.volume,
	}));

	return (
		<div className='table' id='trade'>
			<div className='container'>
				<div className='table__wrapper'>
					<table className='table__table'>
						<thead>
							<tr>
								<th>{t('lang43')}</th>
								<th>{t('lang44')}</th>
								<th>{t('lang45')}</th>
							</tr>
						</thead>
						<tbody>
							{tradeInfo.map(({ currency, price, volume }) => (
								<tr key={currency}>
									<td>{currency}/USDT</td>
									<td>{price}</td>
									<td>{volume}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Table;
